<template>
        <content-loader v-if="loaded==false && slider!=true" class="load-global"
            viewBox="0 0 520 200"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
            >
            <rect x="20" y="0" rx="0" ry="0" width="150" height="150" />
            <rect x="20" y="160" rx="3" ry="3" width="150" height="6" />
            <rect x="180" y="0" rx="0" ry="0" width="150" height="150" />
            <rect x="180" y="160" rx="3" ry="3" width="150" height="6" />
            <rect x="350" y="0" rx="0" ry="0" width="150" height="150" />
            <rect x="350" y="160" rx="3" ry="3" width="150" height="6" />
        </content-loader>
        <template v-for="(favorite, index) in favorites" :key="favorite.id">
            
    
            <div class="carousel-cell product" :class="{opaque:loaded==false && slider!=true}" >

           
            <a :href="'/collection/'+favorite.glass.id+'/'+favorite.id+'?community=1'"></a>
            
            <img src="../../../../public/assets/collectionglass/space.gif"  class="product-image-space" />

            <div v-if="loaded==false && slider==true">
                <content-loader class="loader-image"  
                    viewBox="0 0 400 470"
                    primaryColor="#f3f3f3"
                    secondaryColor="#cccccc"
                    >
                    <rect x="0" y="0" rx="0" ry="0" width="400" height="470" />
                
                </content-loader></div>
                
            <div v-if="loaded==true" class="photo-single product-image collec">
                <content-loader class="loader-image"  v-if="(photosLoaded[index]<photoDatas[index].length && favorite.glass.id!=22) || (photosLoaded[index]<photoDatas[index].length-1 && favorite.glass.id==22)"
                    viewBox="0 0 400 470"
                    primaryColor="#f3f3f3"
                    secondaryColor="#cccccc"
                    >
                    <rect x="0" y="0" rx="0" ry="0" width="400" height="470" />
                
                </content-loader>


                <img v-for="(layer, index_layer) in photoDatas[index]" :key="index_layer"  @load="photosLoaded[index]+=1" :src="calculatePhoto(layer)" :class="{'render-layer2':index_layer>0}"  :style="'opacity:'+getOpacityElement(index,index_layer)+';'" />

            
            </div>


            <!-- <CartImage :cart_item="favorite" /> -->

            <div class="personnalisation-actions">
                
                    
                
                    <div  v-if="language=='fr'" class="customized_by" ><div class="img community" :style="'background-image:url('+favorite.community_photo+')'"></div> Personnalisé par <u>{{favorite.community_name}}</u></div>
                    <div  v-else class="customized_by"><div class="img community" :style="'background-image:url('+favorite.community_photo+')'"></div> Customized by  <u>{{favorite.community_name}}</u></div>

               
            </div>

            
            <template >
                <div class="like" @click="likePhoto(favorite.glass.liked, favorite.glass.like_id, index)">
                    <img v-if="favorite.glass.liked == false" src="../../../../public/assets/images/heart.svg" width="23" />
                    <img v-else src="../../../../public/assets/images/heart.png" width="23" />
                </div>
            </template>
            

            <div class="bottom">
                <div class="left">
                    <span class="product-title">{{ favorite.glass.name }}
                        
                        <span class="subtitle"><br />{{favorite.glass.brand_name}}</span>
              
                
                </span>
                    <span class="product-price"><span v-if="favorite.tint.id != 5">{{ favorite.glass.price+20 }}</span><span v-else>{{ favorite.glass.price }}</span>€</span>
                </div>
                
                   
                
            </div>
           
    </div>
</template>
    <!-- <AvailableSoon v-for="(l,index) in loop" :key="index"/> -->

</template>

<script>
import CartImage from '../Cart/CartImageComponent.vue';
import AvailableSoon from '../../cards/AvailableSoonCardComponent.vue';
import {APIRequest} from "@simb2s/senseye-sdk"
import { ContentLoader } from 'vue-content-loader'
export default {
    name: "CollectionProductCommunity",
    components: {
        AvailableSoon,
        CartImage,
        ContentLoader
    },
    props: ["favorites","language",'beta','slider'],
    watch: {
    // whenever question changes, this function will run
    
  },
    methods:{
        getOpacityElement(index, layer) {
           
            if(this.photoDatas[index][layer].presets.opacity != null) {
                return this.photoDatas[index][layer].presets.opacity/100
            }
            else {
                return 1
            }
        },
        calculatePhoto(layer_info) {
            let hue = layer_info.presets.hue_app ? layer_info.presets.hue_app : 100
            let saturation = layer_info.presets.saturation ? layer_info.presets.saturation : 100
            let brightness = layer_info.presets.brightness ? layer_info.presets.brightness : 100


            let url
            if(brightness==100 && hue==100 && saturation==100)
            {
            url = 'https://api.sens-eye.fr/render_parts/'+layer_info.render
            }
            else {
            url = 'https://api.sens-eye.fr/api/photo-single-test?photo='+layer_info.render+'&hue='+hue+'&saturation='+saturation+'&brightness='+brightness
            }

            //this.$emit('calculatePhotoEv',0)

            return url
            

        },
        async calculatePhotos()
        {


            this.photosLoaded=[]
            this.photosHoverLoaded=[]
            this.photoDatas=[]
         
            
            let model_parts_arr=[]

            for(let p=0;p<this.favorites.length;p++){
                this.photosLoaded.push(0)
                this.photosHoverLoaded.push(0)
                
                let request_modelparts = '?glass='+this.favorites[p].glass.id
               // let model_partss =  await APIRequest.list_with_request('model-parts',request_modelparts)
                
            
                let model_parts = this.favorites[p].glass.model_parts
                

                let request='?glass='+this.favorites[p].glass.id+'&branch=4'

                for(let i=0;i<model_parts.length;i++) {
        
                    if(model_parts[i].render_type!="shadow" && model_parts[i].render_type!="hinge"){
                        if(model_parts[i].type=='color') {
                            if(model_parts[i].render_slug=="frame")
                            {
                            // ON parcoure les order cart_item customizations pour voir si il y a le meme id que le model part courant
                            let founded=false
                            // Si oui on precise le param
                                for(let k=0;k<this.favorites[p].order_item_customizations.length;k++)
                                {
                                    if(this.favorites[p].order_item_customizations[k].model_part.id==model_parts[i].id)
                                    {
                                        request+='&color_'+model_parts[i].render_slug+'='+this.favorites[p].order_item_customizations[k].color.id
                                        request+='&texture_style_'+model_parts[i].render_slug+'=0'
                                    
                                    //   params['color_'+model_parts[i].render_slug]=cart_item.order_cart_item_customizations[k].color.id
                                    //   params['texture_style_'+model_parts[i].render_slug]=0
                                    founded=true
                                    }
                                    
                                }
                                if(founded==false) {
                                    if(model_parts[i].material=="plastic")
                                    {
                                        request+='&color_'+model_parts[i].render_slug+'=10'
                                    //  params['color_'+model_parts[i].render_slug]=10
                                    }
                                    else {
                                        request+='&color_'+model_parts[i].render_slug+'=19'
                                    //params['color_'+model_parts[i].render_slug]=23
                                    }
                                    
                                }
                            
                            }
                            else if(model_parts[i].render_slug=="temples") {
                                let founded=false
                                for(let k=0;k<this.favorites[p].order_item_customizations.length;k++)
                                {
                                    if(this.favorites[p].order_item_customizations[k].model_part.id==model_parts[i].id)
                                    {
                                        request+='&color_'+model_parts[i].render_slug+'='+this.favorites[p].order_item_customizations[k].color.id
                                    //params['color_'+model_parts[i].render_slug]=cart_item.order_cart_item_customizations[k].color.id
                                
                                    founded=true
                                    }
                                }
        
                                if(founded==false) {
                                    if(model_parts[i].material=="plastic")
                                    {
                                        request+='&color_'+model_parts[i].render_slug+'=10'
                                    //  params['color_'+model_parts[i].render_slug]=10
                                    }
                                    else {
                                        request+='&color_'+model_parts[i].render_slug+'=23'
                                    //params['color_'+model_parts[i].render_slug]=23
                                    }
                                    
                                }
            
                            }
                            else {
                                let founded=false
                                for(let k=0;k<this.favorites[p].order_item_customizations.length;k++)
                                {
                                    if(this.favorites[p].order_item_customizations[k].model_part.id==model_parts[i].id)
                                    {
                                        request+='&color_'+model_parts[i].render_slug+'='+this.favorites[p].order_item_customizations[k].color.id
                                    // params['color_'+model_parts[i].render_slug]=cart_item.order_cart_item_customizations[k].color.id
                                
                                    founded=true
                                    }
                                }
    
                                if(founded==false) {
                                    if(model_parts[i].material=="plastic")
                                    {
                                        request+='&color_'+model_parts[i].render_slug+'=10'
                                    }
                                    else {
                                        request+='&color_'+model_parts[i].render_slug+'=19'
                                    }
                                
                                }
            
                            }
            
                            request+='&texture_'+model_parts[i].render_slug+'=4'
        
                        }
                                else if(model_parts[i].type=='texture_color') {
                                    if(model_parts[i].render_slug=="frame")
                                    {
                        
                                        // ON parcoure les order cart_item customizations pour voir si il y a le meme id que le model part courant
                                        let founded=false
                                        // Si oui on precise le param
                                        for(let k=0;k<this.favorites[p].order_item_customizations.length;k++)
                                        {
                        
                                            if(this.favorites[p].order_item_customizations[k].model_part.id==model_parts[i].id)
                                            {
                            
                                                founded=true
                                    
                                                request+='&color_'+model_parts[i].render_slug+'='+this.favorites[p].order_item_customizations[k].color.id

                                                if(this.favorites[p].order_item_customizations[k].texture_style)
                                                {
                                                    request+='&texture_style_'+model_parts[i].render_slug+'='+this.favorites[p].order_item_customizations[k].texture_style.id
                                                }
                                                else 
                                                {
                                                    request+='&texture_style_'+model_parts[i].render_slug+'=0'
                                                }

                                                if(this.favorites[p].order_item_customizations[k].texture)
                                                {
                                                    request+='&texture_'+model_parts[i].render_slug+'='+this.favorites[p].order_item_customizations[k].texture.id
                                                }
                                                else 
                                                {
                                                    request+='&texture_'+model_parts[i].render_slug+'=4'
                                                }
                            
                            //params['texture_style_'+model_parts[i].render_slug]=
                            
                            //params['texture_'+model_parts[i].render_slug]=cart_item.order_cart_item_customizations[k].texture ? cart_item.order_cart_item_customizations[k].texture.id : 4
                                            }
                            
                                        }
                
                                        if(founded==false) {
                                            if(model_parts[i].material=="plastic")
                                            {
                                                request+='&color_'+model_parts[i].render_slug+'=10'
                                            // params['color_'+model_parts[i].render_slug]=10
                                            }
                                            else {
                                                request+='&color_'+model_parts[i].render_slug+'=19'
                                            //params['color_'+model_parts[i].render_slug]=19
                                            }
                                            
                                            request+='&texture_style_'+model_parts[i].render_slug+'=0'
                                            request+='&texture_'+model_parts[i].render_slug+'=4'

                                            // params['texture_style_'+model_parts[i].render_slug]= 0
                                        
                                            // params['texture_'+model_parts[i].render_slug]=4
                                        }
                        
                                    }
                                    else if(model_parts[i].render_slug=="temples") {
                                        let founded=false
                                        for(let k=0;k<this.favorites[p].order_item_customizations.length;k++)
                                        {
                                            if(this.favorites[p].order_item_customizations[k].model_part.id==model_parts[i].id)
                                            {
                                                request+='&color_'+model_parts[i].render_slug+'='+this.favorites[p].order_item_customizations[k].color.id
                                            //params['color_'+model_parts[i].render_slug]=cart_item.order_cart_item_customizations[k].color.id

                                            if(this.favorites[p].order_item_customizations[k].texture)
                                            {
                                                request+='&texture_'+model_parts[i].render_slug+'='+this.favorites[p].order_item_customizations[k].texture.id
                                            }
                                            else 
                                            {
                                                request+='&texture_'+model_parts[i].render_slug+'=4'
                                            }

                                            request+='&texture_style_'+model_parts[i].render_slug+'=0'
                                            //params['texture_style_'+model_parts[i].render_slug]= 0
                                            founded=true
                                            }
                                        }
                
                                        if(founded==false) {
                                            if(model_parts[i].material=="plastic")
                                            {
                                                request+='&color_'+model_parts[i].render_slug+'=10'
                                            // params['color_'+model_parts[i].render_slug]=10
                                            }
                                            else {
                                                request+='&color_'+model_parts[i].render_slug+'=23'
                                            //params['color_'+model_parts[i].render_slug]=23
                                            }

                                            request+='&texture_'+model_parts[i].render_slug+'=4'
                                
                                            //params['texture_'+model_parts[i].render_slug]= 4

                                            request+='&texture_style_'+model_parts[i].render_slug+'=0'
                                            //params['texture_style_'+model_parts[i].render_slug]= 0
                                        }
                        
                        
                                    }
                                else {
                                    let founded=false
                                    for(let k=0;k<this.favorites[p].order_item_customizations.length;k++)
                                    {
                                        if(this.favorites[p].order_item_customizations[k].model_part.id==model_parts[i].id)
                                        {

                                            request+='&color_'+model_parts[i].render_slug+'='+this.favorites[p].order_item_customizations[k].color.id
                                        // params['color_'+model_parts[i].render_slug]=cart_item.order_cart_item_customizations[k].color.id
                                    
                                        founded=true
                                        }
                                    }
                            
                                    if(founded==false) {
                                        if(model_parts[i].material=="plastic")
                                        {
                                            request+='&color_'+model_parts[i].render_slug+'=10'
                                        // params['color_'+model_parts[i].render_slug]=10
                                        }
                                        else {
                                            request+='&color_'+model_parts[i].render_slug+'=19'
                                        //params['color_'+model_parts[i].render_slug]=19
                                        }
                                    
                                    }
                                    request+='&texture_'+model_parts[i].render_slug+'=4'
                        //params['texture_'+model_parts[i].render_slug]=4
                        
                                }
                        
                        
                            }
                            else if (model_parts[i].render_type=="lense")
                            {

                                if(this.favorites[p].tint)
                            {
                                request+='&tint_'+model_parts[i].render_slug+'='+this.favorites[p].tint.id
                            // params['color_'+model_parts[i].render_slug]=10
                            }
                            else {
                                request+='&tint_'+model_parts[i].render_slug+'=5'
                            //params['color_'+model_parts[i].render_slug]=19
                            }
                        
                        
                    
                            }
                    
                    
                    }
                }

                let datas = await APIRequest.list_renders('photos-layers-new', request)
                        //let render_infos =  await APIRequest.list_renders('photos-layers-new',request)
                        //let render_infos_datas=render_infos


                if(this.favorites[p].glass.id==26)
                {
                    this.photoDatas.push(datas.scene1) 
                }
                else
                {
                    this.photoDatas.push(datas.scene2) 
                }
                
            
                 
                
               
            }

            
        }
        
    },
    data(){
        return{
            hideHover:false,
            
            collectionPhotosLoading:[],
            loop: 3,
            model_parts:null,
            loadingFilter:false,
            current_colors:[],
            loaded:false,
            photosLoaded:[],
            photosHoverLoaded:[],
            photoHoverDatas:null,
            photoDatas:null,
            selectedColorFilterHover:'all'
        }
    },
    async mounted(){
        await this.calculatePhotos()
        this.loaded=true
        this.$forceUpdate();
    },
    updated(){

    
        
       
        if(this.slider==true)
        {
            this.$emit('updateSliderEv')
        }
       
       
        
    }
}
</script>

<style scoped>
.carousel-cell a
{
    text-decoration: none;
    color:#000;
    position:absolute;
    z-index:3;
    left:0;
    top:0;
    width:100%;
    height:100%;
}

.personnalisation-actions
{
    position:absolute;
    top:10px;
    text-align: left;
    left:15px;
    z-index:3;
}

.colors-count
{
    
    font-size:13px;

}
.colors-count .pls
{
background: linear-gradient(80deg, #95B4F2 0%, #9F96F0 100%) 0% 0% no-repeat padding-box;
-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.product {
  margin-bottom:150px;
}
.personnalisation-button::before
{
    content:' ';
    position:absolute;
    left:5px;
    top: 7px;
    width:13px;
    height:13px;
    background-size:100% 100%;
    display:block;
    background-image:url(../../../../public/assets/images/bullet.svg);
}
.personnalisation-button
{
    position:relative;
    font-size:13px;
    background: transparent linear-gradient(90deg, #CBDCF8 0%, #DDD5F7 100%) 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF00;
  border-radius: 8px;
  padding:5px 10px 5px 25px;
}
.like img {
    width:23px !important;
}

.like
{
    cursor:pointer;
    position:absolute;
    top:10px;
    z-index:13;
    text-align: right;
    right:15px;
}.personnalisation-text
{
    position:relative;
    top:2px;
    margin-left:5px;
    color:transparent;
    font-size:12px;
    background: transparent linear-gradient(89deg, rgba(0, 0, 255, 0.6) 0%, #0000FFE6 100%) 0% 0% no-repeat padding-box;
opacity: 1;
background-clip:text;
}
.like {
    right:20px;
    top:20px; 
}
.personnalisation-actions.beta img {
    display:inline-block;
    width:55px !important;
    height:14px !important;
    position: relative;
    top:4px;
}
.personnalisation-actions {
    display:flex;
    left:20px;
    top:20px;
}
.product-image-space.loading {
    position:relative;
    z-index:2;
    backdrop-filter: blur(30px);
}
.product-image {
width:100%;
height:auto;
}
.product-image.collec {
    position:absolute;
    left:0;
    
    top:50%;
    transform: translateY(-50%);
    
}
.product-image-space {
    width:100%;
    height:auto;
    margin-top:30px;
    margin-bottom:30px;
}
.render-layer2 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 2;
}
.product.opaque {
    opacity:0 !important;
}
.product-price .from {
    font-size:13px;
color:#ACACAC;
font-weight: 400;
}
.product-price {
    font-size:21px !important;
    font-weight: 600;
}
.render-layer3 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 3;
}
div.products div.product .bottom .product-price {
    float:right;
}
.personnalisation-text img {
    display: inline-block;
}
.render-layer4 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 4;
}
.render-layer5 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 5;
}
.render-layer6 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 6;
}
.render-layer7 {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:auto;
    z-index: 7;
}

.photo-single {
    position: relative;
    z-index: 2;
}
.product-image-hover {
    position:absolute;
    width:100%;
    height:auto;
    z-index:8;
    left:0;
    background: #F8F8F8;
    top:0;
    transition:all 0.5s;
    opacity:0;
}

.carousel-cell a
{
    text-decoration: none;
    color:#000;
    position:absolute;
    z-index:13;
    left:0;
    top:0;
    width:100%;
    height:100%;
}
.products .product .bottom .product-title .subtitle img {
    display: inline-block;
    position:relative;
    top:2px;
}
.products .product .bottom .product-title .subtitle {
    font-size:18px;
    font-weight: 400;
    text-transform: none;
    color:rgba(0, 0, 0, 0.47);
}
.products .product .bottom .product-title
{
	display: inline-block;
    font-size:22px;
    font-weight: 600;
	text-transform: uppercase;
}

.products .product .bottom .left {
    width:100%;
    position:relative;
    left:-10px;
    line-height: 1;
    top:70px;
}
.products .product .bottom .right {
    text-align: right;
    width:60%;
}
.colors-count {
    position:absolute;
    right:20px;
    bottom:20px;
    z-index:2;
}
.products .product:hover .bottom .colors {
    opacity:1;
}
.products .product {
    margin-bottom:150px;
}
.products .product .bottom .colors {
    opacity:0;
    transition:all 0.3s;
    position:absolute;
    bottom:0;
    left:0;
    width:95%;
    padding:10px;
    display:flex;
    justify-content: center;
    background:#fff;
    align-items: center;
}
.load-global {
    width:80vw !important;
    max-width:80vw !important;
    position:absolute;
    left:50%;
    transform: translateX(-50%);
}
.loader-image {
    position:absolute;
    top:0;
    left:0;
    z-index:10;
}
.products .product .bottom .colors .color {
    display:flex;
    width:16px;
    cursor: pointer;
    height:16px;
    border:1px solid #C0C0C0;
    position:relative;
    border-radius:50%;
    margin-right:5px;
    margin-left:5px;
}
.customized_by {
    font-size: 16px;
}
.img.community
{
    width:18px;
    height:18px;
    border-radius:50%;
    background-size: cover;
    background-position: center center;
}
.customized_by .img
{
  
    display:inline-block;
    border-radius:50%;
    width:18px;
    position:relative;
    top:3px;
    margin-right:3px;
    height:18px;
}
.products .product .bottom .colors .color.active {
    border:1px solid #000;
}
.products .product .bottom
{
    display:flex;

}
.products .product .bottom .colors .plus {
    margin-right: 20px;
}
.products .product .bottom .colors .plus img {
    width:11px;
}
.products .product .bottom .colors .color .color-bg {
    width:14px;
    height:14px;
    border-radius:50%;
    position:absolute;
    left:0px;
    top:0px;
    border:0;
    background-size:cover;
    background-position:center center;
}
div.products div.product .bottom {
    z-index:14;
    bottom: 10px;
    left:10px;
}
</style>